* {
  padding: 0;
  box-sizing: border-box;
  margin: 0;
}

html {
  font-size: 85%; 
  @media screen and (min-height: 700px) {
    font-size: 100%;
  }
}

.companyLogo {
  max-width: 250px;
}